
import React from 'react';
import LabelLang from 'src/lang/components/LabelLang';

const KEY = 'error.serverCode';

const exchangeError = {
  not_enough_balance: 'not_enough_balance',
  need_kyc: 'need_kyc'
};

const savingError = {
  trial_term_deposit_not_available: 'trial_term_deposit_not_available',
  not_pro_saving_user: 'not_pro_saving_user',
  '-10002': 'notEnoughConstantRepay',
  '-9027': 'stakingRequireMinTerm',
  unable_to_cancel: 'unable_to_cancel',
  '-10014': 'cryptoDepositSunspend',
};

const lotteryError = {
  cannot_split_void: 'cannot_split_void'
};

const spendingError = {
  invalid_otp: 'otpNotMatched',
  invalid_otp_sms: 'smsNotMatched',
};

const userApiErrs = {
  '-1000': 'registerInvalidEmail',
  '-1001': 'registerInvalidPassword',
  '-1002': 'invalidUserType',
  '-1003': 'registerPasswordNotMatch',
  '-1004': 'emailDoesntExist',
  '-1005': 'registerWithExistEmail',
  '-1006': 'loginFailure',
  '-1080': 'loginFailureCaptchaV2',
  '-1012': 'userInfoInvalidKycPreValidate',
  '-1020': 'createdBankExisted',
  '-1045': 'otpNotMatched',
  '-1026': 'userDocumentCannotChange',
  '-1044': 'missingUserDocuments',
  '-1027': 'userFirstNameInvalid',
  '-1028': 'userLastNameInvalid',
  '-1029': 'userDOBInvalid',
  '-1030': 'userTaxCountryInvalid',
  '-1031': 'userTaxIDNumberInvalid',
  '-1037': 'userPhoneNumberInvalid',
  '-1077': 'invalidCoupon',
  '-1078': 'usedCoupon',
  '-1079': 'restrictedEmail',
  '-1084': 'invalidBankAccountName',
  '-1090': 'userTaxIDNumberExisted',
  '-9004': 'systemError',
  '-1019': 'bankInfoError'
};

const reserveApiErrs = {
  '-2001': 'notEnoughConstant',
  '-2002': 'notKycUser',
  '-2004': 'buyMin35Cst',
  '-2003': 'buyMin5Cst',
  '-2007': 'transferUserNotFound',
  '-2008': 'cannotTransferToYourSelf',
  '-2015': 'violateBonusPolicy',
  '-9026': 'prvMaxWithdraw',
  '-9009': 'inValidWalletAddress',
  '-9033': 'collateralSuspendRecall',
};

const MAPPING = {
  /**server_code: "key intl of Message to display" */
  request_failed: 'requestFailed',
  ...reserveApiErrs,
  ...userApiErrs,
  ...exchangeError,
  ...savingError,
  ...lotteryError,
  ...spendingError,
};

const GENERAL_CODE_400 = `${KEY}.generalCode400`;
const GENERAL_CODE_500 = `${KEY}.generalCode500`;
const GENERAL_ERROR = `${KEY}.generalError`;

/**
 * This function will return human readable message from server error code
 * @param {Object} error
 */
const getServerErrMessage = (error = {}, defaultMsg) => {
  const message = MAPPING[error?.code] && `${KEY}.${MAPPING[error?.code]}`;
  let intKey = message;
  let dom;

  if (!message && !defaultMsg) {
    intKey = GENERAL_ERROR;
    if (error?.status === 400) {
      intKey = GENERAL_CODE_400;
    }

    if (error?.status === 500) {
      intKey = GENERAL_CODE_500;
    }
  }

  dom = intKey && <LabelLang id={intKey} />;

  if (!dom) {
    if (typeof defaultMsg === 'string') {
      dom = <span>{defaultMsg}</span>;
    }

    if (typeof defaultMsg === 'object') {
      dom = defaultMsg;
    }
    intKey = null;
  }

  return {
    dom: dom,
    key: intKey
  };
};

export default getServerErrMessage;
