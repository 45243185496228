import React from 'react';
import configureStore from 'src/redux/store';
import { openModal, closeModal } from 'src/components/modal';
import getServerErrMessage from 'src/utils/errorHandler/getServerErrMessage';

import ErrorPopup from './ErrorPopup';

const store = configureStore();

const getErrMessage = (err) => {
  const serverErr = getServerErrMessage(err);
  if (serverErr?.dom) return serverErr?.dom;
  if (err?.message) return err?.message;
  if (err?.data?.Error?.Message) return err?.data?.Error?.Message;
  return '';
};

const getErrCode = (err) => {
  if (err?.code) return err.code;
  if (err?.data?.Error?.Code) return err.code;
};

const showErrorPopup = ({ error, message, defaultMessage, action, onClose }) => {
  const mess = message || getErrMessage(error) || defaultMessage || JSON.stringify(error);
  const code = getErrCode(error);
  const modalId = 'errorPopup';
  const close = () => store.dispatch(closeModal({ id: modalId }));
  return store.dispatch(openModal({
    id: modalId,
    modalProps: { centered: true, dialogClassName: 'modal-no-padding' },
    render: () => <ErrorPopup error={error} message={mess?.dom || mess} code={code} action={action} onClose={() => {
      close();
      onClose && onClose();
    }} />
  }));
};

export { showErrorPopup };