import makeRequest from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';

export const sendReportError = ({ device, note, fileName, base64 }, recaptcha) => makeRequest({
  url: API_URL.USER.REPORT_ERROR,
  method: 'POST',
  captcha: recaptcha,
  data: {
    Device: device,
    Note: note,
    File: {
      FileName: fileName,
      Base64Data: base64,
    },
  }
});