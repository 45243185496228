import React, { useRef, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, Overlay, Popover } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';

import BrowserDetect from 'src/services/browser-detect';
import {closeModal, openModal} from 'src/components/modal';
import { HtmlLang } from 'src/lang/components';

const PopupHoverStyled = styled.div`
  .actions {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    button {
      padding: 5px 25px;
    }
  }
`;

const ContentStyled = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #58667e;
  line-height: 17px;
  padding: 10px 20px;

  p:last-of-type {
    margin-bottom: 0;
  }
  @media screen and (max-width: 768px) {
    padding: 0px;
    //transform: scale(1.05);
  }

  * {
    font-family: inherit;
    font-size: inherit;
    color: inherit;
  }
`;

const Content = ({ desc, values }) => {
  return (
    <ContentStyled>
      <HtmlLang id={desc} values={values} />
    </ContentStyled>);
};

const ICON_TYPE = {
  solid: 'fas',
  regular: 'far',
  light: 'fal',
  duotone: 'fad',
};

const IconStyled = styled.div`
  display: inline-block;
  cursor: pointer;
  margin-left: 5px;
  > i {
    font-size: ${(props) =>
      props.size === 'auto'
        ? 'inherit'
        : props.size === 'sm'
        ? '0.6rem'
        : props.size === 'lg'
        ? '1.2rem'
        : props.size
        ? props.size
        : '1rem'};
  }
`;

const PopoverStyled = styled(Popover)`
  border-radius: 10px !important;
  border: 1px solid #ebecef !important;
  box-shadow: 0 2px 20px 0 rgba(71, 70, 110, 0.2) !important;
  background: #ffffff;
  max-width: 320px !important;
  margin-top: 0px;

  .popover-arrow::before {
    display: none;
  }

  @media screen and (max-width: 768px) {
    border-radius: 10px;
    max-width: unset;
  }
`;

const MyPopover = ({
  className,
  children,
  title,
  desc,
  content,
  type = 'solid',
  replaceIcon = 'fa-question-circle',
  color = null,
  size = 'auto',
  placement = 'top',
  values = {},
  target,
  showChild = false,
  onShow,
  delay = 0,
  isStickOnHover = false
}, forwardedRef) => {
  const [show, setShow] = useState(false);
  const targetRef = useRef(null);
  const setTimeoutConst = useRef();
  const dispatch = useDispatch();
  const [showPopover, setShowPopover] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', listenScroll);
    return () => {
      window.removeEventListener('scroll', listenScroll);
    };
  }, []);

  useImperativeHandle(forwardedRef, () => {
    return {
      hide: handleHide,
      show: handleShow
    };
  });

  const listenScroll = () => {
    setShow(false);
  };

  const onHideMobile = () => {
    dispatch(closeModal({ id: 'popupHover' }));
  }

  const showDialog = () => {
    return dispatch(openModal({
      id: 'popupHover',
      modalProps: { centered: true, size: 'sm' },
      render: () => {
        return (
          <PopupHoverStyled>
            {content ? (
              <ContentStyled>{content}</ContentStyled>
            ) : (
              <Content desc={desc} values={values} />
            )}
            <div className="actions">
              <Button variant="primary" onClick={onHideMobile}>
                Done
              </Button>
            </div>
          </PopupHoverStyled>
        );
      },
    }));
  };

  const getLayout = (onClick) => {
    return (
      <IconStyled role="presentation" onClick={onClick && onClick} size={size}>
        <i
          className={`${ICON_TYPE[type]} ${replaceIcon} ${color ? color : ''}`}
        />
      </IconStyled>
    );
  };

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (onShow) onShow();
    if (BrowserDetect.isMobile && !showChild) {
      showDialog();
    } else {
      setShow(!show);
    }
  };

  const handleHide = (event) => {
    setShow(false);
  };

  const handleShow = (event) => {
    setShow(true);
  };
  
  const handleMouseEnter = () => {
    setTimeoutConst.current = setTimeout(() => {
      setShow(true);
    }, delay);
  }

  const handleMouseLeave = () => {
    clearTimeout(setTimeoutConst.current);
    setShow(false);
  }

  return (
    <>
      <span ref={targetRef} onClick={handleClick} onMouseEnter={isStickOnHover ? handleMouseEnter : undefined} onMouseLeave={isStickOnHover ? handleMouseLeave : undefined}>
        {children || getLayout()}
      </span>
      <Overlay
        show={show}
        target={target || targetRef.current}
        placement={placement}
        containerPadding={20}
        onHide={handleHide}
        rootClose
        rootCloseEvent="click"
      >
        <PopoverStyled
          id="popover-basic"
          title={title || ''}
          className={className}
          onMouseEnter={isStickOnHover ? handleMouseEnter : undefined}
          onMouseLeave={isStickOnHover ? handleMouseLeave : undefined}
        >
          {content ? (
            <ContentStyled>{content}</ContentStyled>
          ) : (
            <Content desc={desc} values={values} />
          )}
        </PopoverStyled>
      </Overlay>
    </>
  );
};

export default forwardRef(MyPopover);
