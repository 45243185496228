import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import domtoimage from 'dom-to-image';
import smartlookClient from 'smartlook-client';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment-timezone/moment-timezone';

import { URL } from 'src/resources/constants/url';
import Loading from 'src/components/loading';
import { getRecaptcha } from 'src/hocs/hocRecaptcha';
import ga, { events } from 'src/services/ga';

import { sendReportError } from './action';
import styles from './styles.scss';

const ErrorPopup = props => {
  const { error, message, action, code, location, onClose } = props;
  const isAuth = useSelector(state => !!state.auth.token);
  const profile = useSelector(state => state.auth.profile);
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    ga(events.category.errorApi, code || events.action.unknown_error);
    if (isAuth) {
      smartlookClient.error('error-popup');
    }
  }, []);

  const onReport = async () => {
    try {
      setSubmitting(true);
      const banner = document.getElementById('error-popup');
      const dataUrl = await domtoimage.toPng(banner);
      const captchaToken = await getRecaptcha('reportError');
      ga(events.category.errorApi, events.action.report_error);
      await dispatch(sendReportError({
        device: navigator.userAgent,
        note: `email=${profile?.email} time=${moment().toString()} location=${location.pathname} url=${error?.url} data=${JSON.stringify(error)}`,
        fileName: action ? `error_${action}.png` : 'error.png',
        base64: dataUrl.replace(/^data:image\/(png|jpg);base64,/, ''),
      }, captchaToken));
      setSubmitted(true);
    } catch (err) {
      console.log("🚀 ~ file: ErrorPopup.js ~ line 27 ~ onReport ~ err", err)
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className={styles.wrapper} id="error-popup">
      <div className={styles.title}>Oops, there’s been an error</div>
      <div className={styles.body}>
        <div className={styles.content}>
          {submitted 
            ? <>
              <p>Your request has been received and we will respond as soon as possible. In the meantime, you can visit our Help Center to review articles addressing our most commonly asked questions by clicking the link below:</p>
              <Link to={URL.HELP_AND_SUPPORT}>https://www.myconstant.com/help-and-support</Link>
            </>
            : message
          }
        </div>
      </div>
      <div className={styles.foot}>
        <div className={styles.actions}>
          <Button className={styles.close} onClick={onClose}>
            Close
          </Button>
          {isAuth && !submitted && (
            <Button disabled={submitting} className={styles.report} onClick={onReport}>
              {submitting ? <Loading light /> : 'Send report'}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ErrorPopup);