import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import cx from 'classnames';

import { getRecaptcha } from 'src/hocs/hocRecaptcha';
import { SimplePageStyled } from 'src/components/styled';
import OtpInput from 'src/components/otpInput';
import { LabelLang } from 'src/lang/components';
import { OTP_NUM_INPUTS } from 'src/resources/constants/app';
import logo from 'src/assets/images/logo-dark.svg';
import { Button } from 'react-bootstrap';
import { requestOtpPhonePhoneNumber } from 'src/screens/me/pages/Security/smsAuthentication/redux/api';
import { showAlert } from 'src/screens/app/redux/action';
import { showErrorPopup } from 'src/components/errorPopup';
import styles from './styles.scss';
import pinCode from '../img/pin-code.svg';

const getIntlKey = name => `me.smsSettings.inputSms.${name}`;

const TwoFactorFormStyled = styled.div`
  padding: 40px 20px 20px !important;
  text-align: center;
  font-size: 14px;
  .title {
    color: #F5A623;
    margin: 40px 0 10px;
  }
  .desc {
    color: #0A0A0A;
    margin-bottom: 20px;
  }
  .errorMessage {
    font-size: 12px;
  }
  .input-container {
    margin: 10px 0;
  }
  .inputStyle {
    width: 2.5rem!important;
    height: 3.5rem;
    line-height: 3.5rem;
    border-radius: 0px!important;
    padding: 0;
    border: none;
    border-bottom: 1px solid #CDCDCD;
    font-size: 2rem;
    font-weight: 500;
    background: none;
    &:hover, &:focus {
      outline: none;
      border: none;
      border-bottom: 1px solid #CDCDCD;
    }
  }
  
  .cantAccess {
    opacity: 0.39;
    color: #0A0A0A;
    margin-top: 120px;
    cursor: pointer;
  }
`;

class TwoFactorForm extends React.PureComponent {
  otpInputRef;
  formRef;
  timeout;

  constructor(props) {
    super(props);

    this.otpInputRef = React.createRef();
    this.formRef = React.createRef();
  }

  componentWillUnmount() {
    this.handleRejectInput();
  }

  handleChangeOtp = values => {
    const { callback } = this.props;
    if (values.filter(v => !!v).length === OTP_NUM_INPUTS) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const sendCode = values.join('').trim();
        if (sendCode.length >= OTP_NUM_INPUTS) {
          this.otpInputRef.current.reset();

          if (callback) {
            callback(sendCode);
          }
        }
      }, 500);
    }
  };

  getSmsOtpPhone = async () => {
    const { requestOtpPhonePhoneNumber, showAlert} = this.props;
    const captchaToken = await getRecaptcha('action_sms_token');
    requestOtpPhonePhoneNumber(captchaToken).then(res => {
      showAlert({
        message: <LabelLang id={getIntlKey('resendCodeSuccess')} />,
        type: 'success',
      });
    }).catch(err => {
      const self = this;
      if(err.data?.Error?.Code === -1076) {
        return self.getSmsOtpPhone();
      }
      showErrorPopup({ error: err });
    });
  }

  handleRejectInput = () => {
    const { onRequireOTP } = this.props;

    if (onRequireOTP) {
      onRequireOTP(false);
    }
  };

  render() {
    const {  callback } = this.props;
    return (
      <SimplePageStyled>
        <p className={cx('welcomeTitle text-center bold colorLight')}>
          <LabelLang id={getIntlKey('popupTitle')} />
        </p>
        <div className={cx('logoForm')}>
          <img src={logo} alt="MyConstant alternative logo" />
        </div>
        <TwoFactorFormStyled className="wrapper form">
          <img src={pinCode} alt="pin code" />
          <div className="title bold"><LabelLang id={getIntlKey('newTitle')} /></div>
          <div className="desc"><LabelLang id={getIntlKey('newInputCode')} /></div>
          <div className="input-container" ref={this.formRef}>
            <OtpInput
              containerStyle={styles.otpContainer}
              inputStyle="inputStyle"
              onChange={this.handleChangeOtp}
              numInputs={OTP_NUM_INPUTS}
              isInputNum
              shouldAutoFocus
              ref={this.otpInputRef}
            />
          </div>
          <div>
            <Button className="btn-secondary btn-lg" type="button" onClick={this.getSmsOtpPhone}>
              <LabelLang id={getIntlKey('resendCode')} />
            </Button>
          </div>
          <div
            className="cantAccess"
            onClick={() => {
              callback && callback(null);
              this.handleRejectInput();
            }}
          >
            <LabelLang id={getIntlKey('cantAccess')} />
          </div>
        </TwoFactorFormStyled>
      </SimplePageStyled>
    );
  }
}

const mapState = state => ({
});
const mapDispatch = {
  showAlert,
  requestOtpPhonePhoneNumber
};

export default connect(mapState, mapDispatch)(TwoFactorForm);
