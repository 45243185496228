/* eslint-disable react/no-multi-comp */
import React, { PureComponent, useRef, useEffect } from 'react';
import BrowserDetect from 'src/services/browser-detect';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;
const SPACEBAR = 32;

const isStyleObject = obj => typeof obj === 'object';

const SingleOtpInput = props => {
  const {
    separator,
    isLastChild,
    inputStyle,
    focus,
    isDisabled,
    hasErrored,
    errorStyle,
    focusStyle,
    disabledStyle,
    shouldAutoFocus,
    isInputNum,
    value,
    ...rest
  } = props;

  const inputRef = useRef(null);
  useEffect(() => {
    if (inputRef && focus && shouldAutoFocus) {
      inputRef.current.focus();
    }
  }, []);
  useEffect(() => {
    if (inputRef && focus) {
      inputRef.current.focus();
      inputRef.current.select();
    }
  }, [ focus ]);

  const getClasses = (...classes) =>
    classes.filter(c => !isStyleObject(c) && c !== false).join(' ');

  const numValueLimits = isInputNum ? { min: 0, max: 9 } : {};

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <input
        style={Object.assign(
          { width: '1em', textAlign: 'center', padding: 0, minWidth: 45 },
          isStyleObject(inputStyle) && inputStyle,
          focus && isStyleObject(focusStyle) && focusStyle,
          isDisabled && isStyleObject(disabledStyle) && disabledStyle,
          hasErrored && isStyleObject(errorStyle) && errorStyle
        )}
        className={getClasses(
          inputStyle,
          focus && focusStyle,
          isDisabled && disabledStyle,
          hasErrored && errorStyle
        )}
        type='tel'
        {...numValueLimits}
        maxLength="1"
        ref={inputRef}
        disabled={isDisabled}
        value={value ? value : ''}
        {...rest}
      />
      {!isLastChild && separator}
    </div>
  );
};

const OtpInputStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .pasteCode {
    font-size: 12px;
    font-weight: 400;
    min-width: 100px;
  }
`;

class OtpInput extends PureComponent {
  constructor(props) {
    super(props);
    const { activeInput } = props;
    this.state = {
      activeInput: typeof activeInput === 'number' ? activeInput : (BrowserDetect.isMobile ? -1 : 0),
      codes: new Array(props.numInputs),
    };
  }

  reset = () => {
    const { numInputs } = this.props;
    this.setState({ codes: new Array(numInputs), activeInput: 0 });
  }

  // Focus on input by index
  focusInput = input => {
    const { numInputs } = this.props;
    const activeInput = Math.max(Math.min(numInputs - 1, input), 0);

    this.setState({ activeInput });
  };

  // Focus on next input
  focusNextInput = () => {
    const { activeInput } = this.state;
    this.focusInput(activeInput + 1);
  };

  // Focus on previous input
  focusPrevInput = () => {
    const { activeInput } = this.state;
    this.focusInput(activeInput - 1);
  };

  handleOtpChange = values => {
    const { onChange } = this.props;
    this.setState({ codes: values });
    onChange(values);
  };

  // Change OTP value at focused input
  changeCodeAtFocus = value => {
    const { activeInput, codes } = this.state;
    const newArr = [...codes];
    newArr[activeInput] = value;
    this.handleOtpChange(newArr);
  };

  // Handle pasted OTP
  handleOnPaste = e => {
    e.preventDefault();
    const { numInputs } = this.props;
    const { activeInput, codes } = this.state;
    const newArr = [...codes];

    // Get pastedData in an array of max size (num of inputs - current position)
    const pastedData = e.clipboardData
      .getData('text/plain')
      .slice(0, numInputs - activeInput)
      .split('');

    // Paste data from focused input onwards
    for (let pos = 0; pos < numInputs; ++pos) {
      if (pos >= activeInput && pastedData.length > 0) {
        newArr[pos] = pastedData.shift();
      }
    }
    this.handleOtpChange(newArr);
  };

  handleOnChange = e => {
    this.focusNextInput();
    this.changeCodeAtFocus(e.target.value);
  };

  // Handle cases of backspace, delete, left arrow, right arrow, space
  handleOnKeyDown = e => {
    if (e.keyCode === BACKSPACE || e.key === 'Backspace') {
      e.preventDefault();
      this.changeCodeAtFocus('');
      this.focusPrevInput();
    } else if (e.keyCode === DELETE || e.key === 'Delete') {
      e.preventDefault();
      this.changeCodeAtFocus('');
    } else if (e.keyCode === LEFT_ARROW || e.key === 'ArrowLeft') {
      e.preventDefault();
      this.focusPrevInput();
    } else if (e.keyCode === RIGHT_ARROW || e.key === 'ArrowRight') {
      e.preventDefault();
      this.focusNextInput();
    } else if (e.keyCode === SPACEBAR || e.key === ' ' || e.key === 'Spacebar') {
      e.preventDefault();
    }
  };

  checkLength = e => {
    if (e.target.value.length > 1) {
      e.preventDefault();
      this.focusNextInput();
    }
  };

  renderInputs = () => {
    const { activeInput, codes } = this.state;
    const {
      numInputs,
      inputStyle,
      focusStyle,
      separator,
      isDisabled,
      disabledStyle,
      hasErrored,
      errorStyle,
      shouldAutoFocus,
      isInputNum,
    } = this.props;
    const inputs = [];

    for (let i = 0; i < numInputs; i++) {
      inputs.push(
        <SingleOtpInput
          key={i}
          focus={activeInput === i}
          value={codes && codes[i]}
          onChange={this.handleOnChange}
          onKeyDown={this.handleOnKeyDown}
          onInput={this.checkLength}
          onPaste={this.handleOnPaste}
          onFocus={e => {
            this.setState({ activeInput: i });
            e.target.select();
          }}
          onBlur={() => this.setState({ activeInput: -1 })}
          separator={separator}
          inputStyle={inputStyle}
          focusStyle={focusStyle}
          isLastChild={i === numInputs - 1}
          isDisabled={isDisabled}
          disabledStyle={disabledStyle}
          hasErrored={hasErrored}
          errorStyle={errorStyle}
          shouldAutoFocus={shouldAutoFocus && i === 0}
          isInputNum={isInputNum}
        />
      );
    }

    return inputs;
  };

  handlePasteCode = (e) => {
    navigator.clipboard.readText().then(
      clipText => {
        this.handleOtpChange(clipText?.split(''));
      },
    );
  };

  render() {
    const { containerStyle } = this.props;

    return (
      <OtpInputStyled>
        <div
          className={!isStyleObject(containerStyle) ? containerStyle : ''}
          style={Object.assign(
            { display: 'flex' },
            isStyleObject(containerStyle) && containerStyle,
          )}
        >
          {this.renderInputs()}
        </div>
        <Button className="pasteCode" onClick={this.handlePasteCode}>Paste code</Button>
      </OtpInputStyled>
    );
  }
}

OtpInput.defaultProps = {
  numInputs: 4,
  onChange: codes => console.log('OTP', codes),
  isDisabled: false,
  isInputNum: false,
  separator: null,
  focusStyle: null,
  inputStyle: null,
  containerStyle: null,
  disabledStyle: null,
  errorStyle: null,
  hasErrored: false,
  shouldAutoFocus: false,
};

export default OtpInput;
