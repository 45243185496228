import makeRequest from 'src/redux/action';
import { API_URL } from 'src/resources/constants/url';
import { camelizeKeys } from 'src/utils/object';
import {
  REQUEST_OFF_VERIFY_PHONE_NUMBER,
  CHECK_VERIFY_PHONE_NUMBER,
  REQUEST_VERIFY_PHONE_NUMBER,
  CHECK_OFF_VERIFY_PHONE_NUMBER,
  REQUEST_OTP_TOKEN_PHONE_NUMBER,
  REQUEST_OTP_PHONE_PHONE_NUMBER,
  SHOW_INPUT_SMS_DIALOG,
  HIDE_INPUT_SMS_DIALOG,
  AUTHORIZED_INPUT_SMS_DIALOG,
} from './action';

export const requestVerifyPhoneNumber = (phoneNumber, captcha, otp) => makeRequest({
  url: `${API_URL.SMS_AUTHEN.REQUEST_VERIFY_PHONE_NUMBER}`,
  method: 'POST',
  otp,
  captcha,
  type: REQUEST_VERIFY_PHONE_NUMBER,
  data: {PhoneNumber: phoneNumber},
  normalize: res => camelizeKeys(res)
});

export const checkVerifyPhoneNumber = (phoneNumber, smsCode) => makeRequest({
  url: `${API_URL.SMS_AUTHEN.CHECK_VERIFY_PHONE_NUMBER}`,
  method: 'POST',
  type: CHECK_VERIFY_PHONE_NUMBER,
  data: {PhoneNumber: phoneNumber, Code: smsCode},
  normalize: res => camelizeKeys(res)
});

export const requestOffVerifyPhoneNumber = (captcha, otp) => makeRequest({
  url: `${API_URL.SMS_AUTHEN.REQUEST_OFF_VERIFY_PHONE_NUMBER}`,
  method: 'POST',
  otp,
  captcha,
  type: REQUEST_OFF_VERIFY_PHONE_NUMBER,
  normalize: res => camelizeKeys(res)
});

export const checkOffPhoneNumber = (smsCode) => makeRequest({
  url: `${API_URL.SMS_AUTHEN.CHECK_OFF_VERIFY_PHONE_NUMBER}`,
  method: 'POST',
  type: CHECK_OFF_VERIFY_PHONE_NUMBER,
  data: {Code: smsCode},
  normalize: res => camelizeKeys(res)
});

export const requestOtpTokenPhoneNumber = (captcha, otp) => makeRequest({
  url: `${API_URL.SMS_AUTHEN.REQUEST_OTP_TOKEN_PHONE_NUMBER}`,
  method: 'POST',
  otp,
  captcha,
  type: REQUEST_OTP_TOKEN_PHONE_NUMBER,
  normalize: res => camelizeKeys(res)
});

export const requestOtpPhonePhoneNumber = (captcha) => makeRequest({
  url: `${API_URL.SMS_AUTHEN.REQUEST_OTP_PHONE_PHONE_NUMBER}`,
  method: 'POST',
  captcha,
  type: REQUEST_OTP_PHONE_PHONE_NUMBER,
  normalize: res => camelizeKeys(res)
});

export const showInputSmsDialog = () => dispatch => dispatch({type: SHOW_INPUT_SMS_DIALOG});
export const hideInputSmsDialog = () => dispatch => dispatch({type: HIDE_INPUT_SMS_DIALOG});
export const authorizedSms = (callback, message) => dispatch => dispatch({type: AUTHORIZED_INPUT_SMS_DIALOG, payload: {callback, message}});
